import { allDocs } from "contentlayer/generated";
import {
  GetStaticPaths,
  GetStaticPropsContext,
  InferGetStaticPropsType,
} from "next";
import { useMDXComponent } from "next-contentlayer/hooks";

import { Layout } from "../layouts";

import { MDXComponents } from "~/components/mdx-components";

export default function Page({
  doc,
}: InferGetStaticPropsType<typeof getStaticProps>) {
  const Component = useMDXComponent(doc?.body?.code!);

  return (
    <Layout frontMatter={doc?.frontMatter}>
      <Component components={MDXComponents} />
    </Layout>
  );
}

export const getStaticPaths: GetStaticPaths = async () => {
  const allPaths = allDocs.map((doc) => doc.slug);

  const uniquePaths = Array.from(new Set(allPaths));

  return {
    paths: uniquePaths,
    fallback: false,
  };
};

export const getStaticProps = async (ctx: GetStaticPropsContext) => {
  const slug = Array.isArray(ctx.params?.slug)
    ? ctx.params?.slug.join("/")
    : ctx.params?.slug;

  const component = allDocs.find((c) => {
    return c.slug === `/${slug}`;
  });

  return {
    props: { doc: component },
  };
};
